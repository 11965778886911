import React from "react";
import { Box } from "@mui/material";
import { Logo } from "./components/Logo";
import { Content } from "./components/Content";
import { Footer } from "./components/Footer";

import "./App.css";

function App() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ position: "absolute" }}>
        <Logo />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Content />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "32px" }}
      >
        <Footer />
      </Box>
    </Box>
  );
}

export default App;
