import React from "react";
import Button from "@mui/material/Button";
import { StoreContext } from "../../context";
import { readNFC } from "../../services/NFC";

interface IStartAppButtonProps {
  onGrantPermission: () => void;
}

export const StartAppButton = ({
  onGrantPermission,
}: React.PropsWithChildren<IStartAppButtonProps>) => {
  const { setScanError, setBadgeNumber } = React.useContext(StoreContext);

  const onStart = async () => {
    readNFC(
      (badgeNumber) => setBadgeNumber(badgeNumber),
      (error) => setScanError(error),
      () => onGrantPermission(),
    );
  };

  return (
    <Button
      sx={{
        fontSize: "28px",
        width: "180px",
        height: "180px",
        borderRadius: "50%",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
      }}
      variant="outlined"
      onClick={onStart}
    >
      Start
    </Button>
  );
};
