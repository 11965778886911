import React from "react";
import NfcTwoToneIcon from "@mui/icons-material/NfcTwoTone";
import { Box, Paper, Typography, useTheme } from "@mui/material";

export const ScanBadgeBanner = () => {
  const theme = useTheme();

  return (
    <Box>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "300px",
          height: "300px",
          borderColor: theme.palette.info.main,
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "28px",
            position: "absolute",
            top: "25px",
          }}
        >
          Scan Badge
        </Typography>
        <NfcTwoToneIcon color="info" sx={{ fontSize: "160px" }} />
      </Paper>
    </Box>
  );
};
