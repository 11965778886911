export const checkIfNFCSupported = () => Boolean("NDEFReader" in window);

export const nfcPermissionName = "nfc" as unknown as PermissionName;
export const notSupported = "not supported";

/**
 *   From standard:
 *   "granted",
 *   "denied",
 *   "prompt",
 *   Additional:
 *   "not supported",
 */
export const getNfcPermissionStatus = async (): Promise<string> => {
  if ("permissions" in navigator && "query" in navigator.permissions) {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: nfcPermissionName,
      });
      return permissionStatus.state || notSupported;
    } catch (e) {
      return notSupported;
    }
  }
  return notSupported;
};

export const getNFCReader = () => {
  const isDeviceSupportNFC = checkIfNFCSupported();

  if (!isDeviceSupportNFC) {
    return null;
  }

  return new NDEFReader();
};

export const checkIfNFCPermissionGranted = async () => {
  const ndef = getNFCReader();
  if (!ndef) {
    return false;
  }

  try {
    await ndef.scan();

    return true;
  } catch (error) {
    return false;
  }
};

export const readNFC = async (
  onSuccess: (nfcNumber: number) => void,
  onError: (error: string) => void,
  onGrandPermission?: () => void,
) => {
  const ndef = getNFCReader();

  if (!ndef) {
    onError("This device does not support NFC technology");
    return;
  }

  try {
    await ndef.scan();

    onGrandPermission && onGrandPermission();

    ndef.onreading = (event) => {
      const badgeNumber = parseInt(
        event.serialNumber.split(":").reverse().join(""),
        16,
      );
      onSuccess(badgeNumber);
    };
  } catch (error) {
    onError(`Unable to scan badge number ${error}`);
  }
};
