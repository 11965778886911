import React from "react";
import { Typography, Box } from "@mui/material";
import WatchLaterTwoToneIcon from "@mui/icons-material/WatchLaterTwoTone";

export const Logo = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", padding: "8px" }}>
      <WatchLaterTwoToneIcon sx={{ fontSize: "64px" }} />
      <Typography sx={{ marginLeft: "8px" }} variant="h5" component="h2">
        Employee Worked Hours
      </Typography>
    </Box>
  );
};
