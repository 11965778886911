import React from "react";
import { Error } from "../Error";
import { Box } from "@mui/material";
import { IHours } from "../../types";
import { HoursTable } from "../HoursTable";
import { StoreContext } from "../../context";
import { request } from "../../services/request";
import { StartAppButton } from "../StartAppButton";
import { ScanBadgeBanner } from "../ScanBadgeBanner";
import {
  checkIfNFCSupported,
  checkIfNFCPermissionGranted,
  readNFC,
  getNfcPermissionStatus,
  notSupported,
} from "../../services/NFC";
import Button from "@mui/material/Button";

export const Content = () => {
  const isDeviceSupportNFC = checkIfNFCSupported();
  const [isApplicationStarted, setIsApplicationStarted] = React.useState(false);
  const [nfcPermissionStatus, setNfcPermissionStatus] =
    React.useState(notSupported);

  const {
    hours,
    setHours,
    setHaveHoursReceived,
    haveHoursReceived,
    badgeNumber,
    scanError,
    setScanError,
    setBadgeNumber,
  } = React.useContext(StoreContext);

  React.useEffect(() => {
    const fetchHours = async () => {
      try {
        const hours = await request.get<Array<IHours>>(
          `/worked-hours/${badgeNumber}`,
        );
        setHours(hours);
        setHaveHoursReceived(true);
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setScanError((err as any).response.data);
      }
    };

    if (badgeNumber) {
      fetchHours();
    }
  }, [
    badgeNumber,
    setBadgeNumber,
    setHaveHoursReceived,
    setHours,
    setScanError,
  ]);

  React.useEffect(() => {
    const checkPermission = async () => {
      const isNFCPermissionGranted = await checkIfNFCPermissionGranted();
      const nfcPermissionStatus = await getNfcPermissionStatus();
      setNfcPermissionStatus(nfcPermissionStatus);

      if (isNFCPermissionGranted) {
        readNFC(
          (badgeNumber) => setBadgeNumber(badgeNumber),
          (error) => setScanError(error),
        );

        setIsApplicationStarted(true);
      }
    };

    checkPermission();
  }, [setBadgeNumber, setScanError]);

  React.useEffect(() => {
    if (badgeNumber !== undefined && isNaN(badgeNumber)) {
      readNFC(
        (badgeNumber) => setBadgeNumber(badgeNumber),
        (error) => setScanError(error),
      );
    }
  }, [badgeNumber, setBadgeNumber, setScanError]);

  const startScanning = async () => {
    await readNFC(
      () => {
        console.log("success");
        window.location.reload();
      },
      () => {
        console.log("Something went wrong");
      },
    );
  };

  let content = (
    <StartAppButton onGrantPermission={() => setIsApplicationStarted(true)} />
  );

  if (!isDeviceSupportNFC) {
    content = <Error>This device does not support NFC technology</Error>;
  }
  if (scanError) {
    content = <Error>{scanError}</Error>;
  }
  if (isApplicationStarted) {
    content = (
      <Box sx={{ margin: "72px 0" }}>
        <ScanBadgeBanner />
      </Box>
    );
  }
  if (haveHoursReceived) {
    content = (
      <Box
        sx={{
          width: {
            sm: "100%",
            md: "90%",
            lg: "80%",
          },
          padding: "0 8px",
          overflow: "hidden",
          marginTop: "80px",
          marginBottom: "32px",
        }}
      >
        <HoursTable hours={hours} />
      </Box>
    );
  }

  if (["denied", "prompt"].includes(nfcPermissionStatus)) {
    content = (
      <Button type="submit" variant="contained" onClick={startScanning}>
        Start Scanning
      </Button>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {content}
    </Box>
  );
};
