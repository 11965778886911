import * as React from "react";
import { IHours } from "../../types";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { generateBeautifulDate } from "../../services/date";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IHoursTableProps {
  hours: Array<IHours>;
}

export const HoursTable = ({
  hours,
}: React.PropsWithChildren<IHoursTableProps>) => {
  const parsedHours = React.useMemo(() => {
    return hours.map((hour) => ({
      ...hour,
      date: generateBeautifulDate(hour.date),
    }));
  }, [hours]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="left">Week</StyledTableCell>
            <StyledTableCell align="left">First Name</StyledTableCell>
            <StyledTableCell align="left">Last Name</StyledTableCell>
            <StyledTableCell align="left">Overtime Hours</StyledTableCell>
            <StyledTableCell align="left">Regular Hours</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {parsedHours.map((row) => (
            <StyledTableRow
              key={row.date}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell>{row.date}</StyledTableCell>
              <StyledTableCell align="left">{row.week}</StyledTableCell>
              <StyledTableCell align="left">{row.firstName}</StyledTableCell>
              <StyledTableCell align="left">{row.lastName}</StyledTableCell>
              <StyledTableCell align="left">{row.overtimeHrs}</StyledTableCell>
              <StyledTableCell align="left">{row.regularHrs}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
