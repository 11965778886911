import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";

export const Error = ({ children }: React.PropsWithChildren) => {
  const theme = useTheme();
  const redColor = theme.palette.error.light;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "16px",
        border: `1px solid ${redColor}`,
        borderRadius: "4px",
        fontSize: "22px",
      }}
    >
      <ErrorOutlineTwoToneIcon sx={{ color: redColor }} fontSize="inherit" />
      <Typography
        fontSize="inherit"
        sx={{ marginLeft: "16px", color: redColor }}
      >
        {children}
      </Typography>
    </Box>
  );
};
