import { Typography } from "@mui/material";
import React from "react";
import { StoreContext } from "../../context";

export const Footer = () => {
  const [seconds, setSeconds] = React.useState(
    Number(process.env.REACT_APP_SHOW_TABLE_SECONDS),
  );

  const { haveHoursReceived, setHaveHoursReceived, setHours, setBadgeNumber } =
    React.useContext(StoreContext);

  React.useEffect(() => {
    let timerId: NodeJS.Timer | null = null;
    if (haveHoursReceived) {
      timerId = setInterval(() => {
        if (seconds === 1) {
          setHours([]);
          setHaveHoursReceived(false);
          setSeconds(Number(process.env.REACT_APP_SHOW_TABLE_SECONDS));
          setBadgeNumber(NaN);

          return;
        }

        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      timerId && clearInterval(timerId);
    };
  }, [
    haveHoursReceived,
    seconds,
    setBadgeNumber,
    setHaveHoursReceived,
    setHours,
  ]);

  if (!haveHoursReceived) {
    return null;
  }

  return (
    <Typography sx={{ fontSize: "18px" }}>
      Table will be hidden in{" "}
      <Typography sx={{ fontWeight: "bold", display: "inline" }}>
        {seconds}
      </Typography>{" "}
      seconds
    </Typography>
  );
};
