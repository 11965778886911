import React, { Dispatch, SetStateAction } from "react";
import { IHours } from "../types";

interface IStoreContextState {
  badgeNumber?: number;
  setBadgeNumber: Dispatch<SetStateAction<number>>;

  scanError: string;
  setScanError: Dispatch<SetStateAction<string>>;

  hours: Array<IHours>;
  setHours: Dispatch<SetStateAction<Array<IHours>>>;

  haveHoursReceived: boolean;
  setHaveHoursReceived: Dispatch<SetStateAction<boolean>>;
}

export const StoreContext = React.createContext({
  setBadgeNumber: () => ({}),

  scanError: "",
  setScanError: () => ({}),

  hours: [],
  setHours: () => ({}),

  haveHoursReceived: false,
  setHaveHoursReceived: () => ({}),
} as IStoreContextState);

export const StoreContextProvider = ({ children }: React.PropsWithChildren) => {
  const [scanError, setScanError] = React.useState("");
  const [badgeNumber, setBadgeNumber] = React.useState(NaN);
  const [hours, setHours] = React.useState<Array<IHours>>([]);
  const [haveHoursReceived, setHaveHoursReceived] = React.useState(false);

  return (
    <StoreContext.Provider
      value={{
        hours,
        setHours,
        haveHoursReceived,
        setHaveHoursReceived,
        badgeNumber,
        setBadgeNumber,
        scanError,
        setScanError,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
