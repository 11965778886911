import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const get = async <
  T extends Record<string, unknown> | Array<Record<string, unknown>>,
>(
  ...params: Parameters<typeof axiosInstance.get>
): Promise<T> => {
  const response = await axiosInstance.get<T>(...params);

  return response.data;
};

const post = async <T extends Record<string, unknown>>(
  ...params: Parameters<typeof axiosInstance.post>
): Promise<T> => {
  const response = await axiosInstance.post<T>(...params);

  return response.data;
};

export const request = {
  get,
  post,
};
